.uploadDiv {
    background-color: #0078A2;
    border-radius: 15rem;
    padding: 2rem;
    transition: background-color 0.3s ease-in-out;
    cursor: grab;
    margin: 0.5rem
}

.uploadDiv:hover {
    background-color: var(--secondary-color);
}