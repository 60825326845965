#logoimage {
    height: 5rem;
}

#headerdiv {
    position: relative;
    top: 0px;
    width: 100%;
    z-index: 100;
    background-color: var(--primary-color);
    display: flex;
    border-radius: 0 0 10px 10px;
    animation: 'fadeIn' 1s;
}

#headerdiv-inside {
    display: flex;
    align-items: right;
    align-self: right;
    justify-content: right;
    border-radius: 10px;
    width: 100%;
    animation: 'fadeIn' 1s;
}

#secondaryLogoIcon {
    font-size: 4em;
    color: var(--secondary-color);
    margin-left: 102.5rem;
    animation: fadeIn 1s;
}

#headerTitle {
    font-size: 2em;
    font-weight: 100;
    margin-left: 1rem;
    margin-top: 1rem;
    animation: fadeIn 1s;
}

.ant-menu {
    background-color: var(--primary-color);
    font-size: 1em;
    font-weight: 100;
    font-family: "prophet";
    border-bottom: none;
    animation: 'fadeIn' 1s;
}