#routingIcon {
    font-size: 20em;
    color: #0078A2;
}

#routingIcon:hover {
    font-size: 20em;
    color: var(--green-color) !important;
}

#rotuingIconLink {
    color: var(--green-color) !important;
    text-decoration: none;
}

#routingIconLink:hover {
    text-decoration: none;
    color: var(--green-color) !important;
}

#routingIconLink:focus {
    text-decoration: none;
    color: var(--green-color) !important;
}

#routingIconLink:link {
    text-decoration: none;
}

#routingIconContainer {
    display: flex;
    justify-content: center;
}

#routingIconLinkContainer {
    display: flex;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
    color: var(--green-color);
}

#routingIconLinkContainer:hover {
    display: flex;
    justify-content: center;
    color: var(--green-color) !important;
}

a {
    color: #0078A2;
    text-decoration: none;
}