.full-width-container,
.sixty-percent-container,
.fourty-percent-container,
.half-page-container,
.one-third-container,
.two-thirds-container,
.forty-container-slim,
.small-info-container,
.flexible-container,
.quarter-page-container,
.one-third-container-flexible-height,
.flex-row,
.flex-column,
.dark-dropdown-container {
  animation: fadeIn 1s;
}

.full-width-container {
  width: 94vw;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
  height: 45vh;
}

.sixty-percent-container {
  width: 55vw;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
  height: 45vh;
}

.fourty-percent-container {
  width: 35vw;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
  height: 45vh;
}

.half-page-container {
  width: 50vw;
  height: 45vh;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
}

.one-third-container {
  width: 35vw;
  height: 45vh;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
}

.two-thirds-container {
  width: 60vw;
  height: 45vh;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
}

.forty-container-slim {
  width: 35vw;
  height: 20vh;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
}

.small-info-container {
  width: 10.5vw;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  justify-content: center;
  align-items: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 11vh;
  max-height: 30vh;
  border: 1px solid var(--primary-color);
}

.flexible-container {
  width: auto;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 10px;
  height: auto;
}

.quarter-page-container {
  width: 25vw;
  height: 45vh;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
}

.one-third-container-flexible-height {
  width: 35vw;
  height: auto;
  background-color: var(--tertiary-color);
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 10px;
  min-height: 10vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dark-dropdown-container {
  height: auto;
  background-color: var(--primary-color);
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 10px;
  justify-content: center;
  align-items: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 12vh;
  min-height: 8vh;
  border: 1px solid var(--primary-color);
}